import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bacDoan from '../../assets/images/tvbac.png';
import vangDoan from '../../assets/images/tvvang.png';
import bachKim from '../../assets/images/tvbachkim.jpg';
import kimCuong from '../../assets/images/tvKimcuong.jpg';
import centralOrder from '../../assets/images/background_account.jpg';
import ListHome from '../Home/ListHome';
import startOrder from '../../assets/images/start_order.png';
import { useTranslation } from 'react-i18next';
import './Order.css';

import audio from '../../assets/audio/hongbao.mp3';
const axios = require('axios').default;

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

const formatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
});

const normalFormatter = new Intl.NumberFormat();

// const checkToken = () => {
//     let accessToken = localStorage.getItem('auth');
//     if (!accessToken) {
//         localStorage.removeItem('auth');
//         window.location.href = '/account/login';
//     } else {
//         axios
//             .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
//                 headers: {
//                     'x-access-token': accessToken,
//                     'Access-Control-Allow-Origin': '*',
//                 },
//             })
//             .then(function (response) {
//                 let data = response.data;
//                 if (data.status === 'error') {
//                     localStorage.removeItem('auth');
//                     window.location.href = '/account/login';
//                 }
//             })
//             .catch(function (error) {
//                 console.log(error);
//             });
//     }
// };

function Order() {
    const { id_mission } = useParams();
    const newId = useRef(id_mission);
    let [myMission, setMyMission] = useState([{}]);

    const { t } = useTranslation();

    const [isDisableButton, setIsDisableButton] = useState(true);

    const currentTime = new Date().getHours();

    useEffect(() => {
        if (currentTime >= 9 && currentTime <= 24) {
            setIsDisableButton(false);
        }
    }, [currentTime]);

    const [listMission, setListMission] = useState([
        {
            icon: bacDoan,
            rose: 0.5,
            content: `${t('content.home.bacDoan')}`,
            status: 1,
        },
        {
            icon: vangDoan,
            rose: 0.6,
            content: `${t('content.home.vangDoan')}`,
            status: 1,
        },
        {
            icon: bachKim,
            rose: 0.7,
            content: `${t('content.home.bachKim')}`,
            status: 0,
        },
        {
            icon: kimCuong,
            rose: 0.8,
            content: `${t('content.home.kimCuong')}`,
            status: 0,
        },
    ]);

    const [myLevel, setmyLevel] = useState();

    const [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0, roses: 0 }],
        moneyEarn: { moneyEarnPreDay: 0, moneyEarnToday: 0 },
        mission: {
            amount: 0,
            result: 0,
            amountToday: 0,
        },
    });

    const navigate = useNavigate();

    const [isMatching, setIsMatching] = useState(false);

    const mucVonDauTu = [
        { title: 'Thành Viên Bạc', von: 100, loi_nhuan: 0.5 },
        { title: 'Thành Viên Vàng', von: 1000, loi_nhuan: 0.6 },
        { title: 'Thành Viên Bạch Kim', von: 3000, loi_nhuan: 0.7 },
        { title: 'Thành Viên Kim Cương', von: 5000, loi_nhuan: 0.8 },
    ];

    const playAudio = () => {
        new Audio(audio).play();
    };

    const handSendMission = async () => {
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/new`,
                { level: id_mission },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setIsMatching(false);
                    if (data.data.type === 1) {
                        toast.success(`${t('content.order.timThay1DonHang')}`, { theme: 'light' });
                        await sleep(1000);
                        setTimeout(() => {
                            navigate(`/order/confirm/${data.data.mission.id_mission}`);
                        }, 1000);
                        return;
                    }
                    if (data.data.type === 2) {
                        toast.warn(`${t('content.order.chuaCoSpMoi')}`, { theme: 'light' });
                        return;
                    }
                    if (data.data.type === 3) {
                        toast.warn(`${t('content.order.tkBiKhoa')}`, { theme: 'light' });
                        setTimeout(() => {
                            window.location.reload();
                            // navigate(`/order/mission/${id_mission}`);
                        }, 1000);
                        return;
                    }
                    if (data.data.type === 6) {
                        toast.success(`${t('content.order.donHangChuaHT')}`, { theme: 'light' });
                        await sleep(1000);
                        setTimeout(() => {
                            navigate(`/order/confirm/${data.data.mission.id_mission}`);
                        }, 1000);
                        return;
                    }
                    if (data.data.type === 1000) {
                        toast.success(`${t('content.order.daHTDuDon')}`, { theme: 'light' });
                        setTimeout(() => {
                            window.location.reload();
                            // navigate(`/order/mission/${id_mission}`);
                        }, 1000);
                        return;
                    }
                }
            })
            .catch(function (error) {
                setIsMatching(false);
                if (error.response.status === 403) return toast.warn(error.response.data, { theme: 'light' });
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    };

    useEffect(() => {
        // checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    console.log(data);
                    setUser(data);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    useEffect(() => {
        // checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/mission/my`, {
                params: {
                    id_mission: newId.current,
                },
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setMyMission(data.data);
                    if (data.data.name_level === 'Thành Viên Bạc' || data.data.name_level === 'THÀNH VIÊN MỚI')
                        setmyLevel(listMission[0]);
                    if (data.data.name_level === 'Thành Viên Vàng') setmyLevel(listMission[1]);
                    if (data.data.name_level === 'Thành Viên Bạch Kim') setmyLevel(listMission[2]);
                    if (data.data.name_level === 'Thành Viên Kim Cương') setmyLevel(listMission[3]);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);

    // if (!Array.isArray(myMission.missions) || myMission.missions.length <= 0) return false;

    return (
        <div className="main-container">
            {isMatching && (
                <div className="matching-animation">
                    <div class="overlay">
                        <div class="text flex">{t('content.order.khopTuDong')}</div>
                        <div className="loader loader1"></div>
                        <div className="loader loader2"></div>
                        <div className="loader loader3"></div>
                    </div>
                </div>
            )}
            <div className="task_hall_sheet">
                {myLevel && (
                    <div className="task_hall_sheet_box w-[100%] bg-[#f5f5f5] mt-24 rounded-3xl relative flex flex-col items-center justify-center">
                        <img src={myLevel.icon} alt="" className="absolute w-[30%] img-level-order" />
                        <div className="content-mylevel pt-8 pl-4 text-lg font-bold">{myLevel.content}</div>
                        <div className="hoa-hong pt-2 pl-4 text-lg font-bold">
                            {t('content.order.hoaHong')} {myLevel.rose}%
                        </div>
                        <img src={centralOrder} alt="" className="w-[90%] mt-4 rounded-xl" />
                        <ListHome amount={1} type="order" />
                        <img
                            src={startOrder}
                            alt=""
                            className="w-[50%] cursor-pointer"
                            onClick={() => {
                                if (isDisableButton) {
                                    toast.warn(`${t('content.order.timeLayDonHang')}`, { theme: 'light' });
                                    return;
                                }

                                if (user?.data[0].money < 100) {
                                    toast.warn(`${t('content.order.soDuKhongDat')}`, { theme: 'light' });
                                    return;
                                }

                                if (myMission.type === 2) {
                                    setIsMatching(true);
                                    handSendMission();
                                }
                            }}
                        />
                    </div>
                )}
            </div>
            <div className="text-3xl font-bold text-center py-4 text-[#434343]">
                {t('content.order.thanhQuaHomNay')}
            </div>
            <div className="bg-[#f5f5f5] rounded-xl">
                <div className="ant-row ant-row-space-between">
                    <div className="ant-col">
                        <div className="sc-ikJyIC gNkWet">
                            <div className="sc-jJoQJp hzrkvO text-black text-xl">{t('content.order.soDuTK')}</div>
                            <div className="sc-hiCibw iYoREV text-[#fc0303] text-3xl py-2">
                                {formatter.format(user?.data[0].money)}
                            </div>
                            <div className="sc-jJoQJp hzrkvO text-black text-xl pt-8">
                                {t('content.order.hoaHongHomNay')}
                            </div>
                            <div className="sc-hiCibw iYoREV text-[#fc0303] text-3xl py-2">
                                {formatter.format(user?.moneyEarn.moneyEarnToday)}
                            </div>
                        </div>
                    </div>

                    <div className="ant-col">
                        <div className="sc-ikJyIC gNkWet">
                            <div className="sc-jJoQJp hzrkvO text-black text-xl">
                                {t('content.order.soDonHoanThanh')}
                            </div>
                            <div className="sc-hiCibw iYoREV text-[#fc0303] text-3xl py-2">
                                {user?.mission?.amountToday}/{user.data[0].amount}
                            </div>
                            <div className="sc-jJoQJp hzrkvO text-black text-xl pt-8">
                                {t('content.order.soTienDongBang')}
                            </div>
                            <div className="sc-hiCibw iYoREV text-[#fc0303] text-3xl py-2">
                                ${Number((user?.data[0].dongbangtk).toFixed(2))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="luu-y-order bg-[#f5f5f5] mt-8 flex flex-col items-center justify-center rounded-xl">
                <br />
                <span className="bg-[#2f3848] px-4 py-2 rounded-xl text-[#f2d8be] text-xl w-[30%] flex items-center justify-center mb-4">
                    {t('content.order.giaiMa')}
                </span>
                <span className="text-black text-xl px-4 pb-2 text-center leading-10">{t('content.order.luuY')}</span>
            </div>
            <div className="relative bg-[#f5f5f5] mt-8 flex flex-col items-center justify-center rounded-xl">
                <div className="huong-dan py-2 px-4 rounded-xl">
                    <span className="text-white text-xl text-center">{t('content.order.huongDan')}</span>
                </div>
                <div className="content-huong-dan py-4 px-4">
                    <span className="text-black text-xl px-4 pb-2 text-center leading-10">
                        {t('content.order.1')} {user.data[0].amount} {t('content.order.donNhiemVu')}
                        {user.data[0].amount}
                    </span>
                    <br />
                    <span className="text-black text-xl px-4 pb-2 text-center leading-10">{t('content.order.2')}</span>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Order;
